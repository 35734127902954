import _ from 'lodash';
import { SelectOptions } from '../commons/types';
import { myPersonas } from '../store/api/v2/types/auth';
import { IAssessmentData } from '../store/api/types/assessmentStatusDashboard';

export const filterForMultipleValues = (
  // filterFn which allow to add multiple values separated by comma
  row: any,
  id: string,
  filterValue: string
) => {
  const arrayOfFilteredValues = filterValue
    .toLowerCase()
    .split(',')
    .map((el: string) => el.trimStart());

  const arrayOfMatchedProjects = arrayOfFilteredValues.map((el: string) =>
    _.includes((row.getValue(id) as string).toLowerCase(), el)
  );

  return arrayOfMatchedProjects.every((el: boolean) => el);
};

// Remove Duplicate values from DropDonwn List

export const filterDuplicateDropddownTypes = (data: Array<SelectOptions>) =>
  data.reduce((accum, initialValues) => {
    if (
      accum.length &&
      accum.find(
        (item) =>
          item?.value === initialValues.value ||
          item?.text === initialValues.text
      )
    )
      return accum;
    else accum.push(initialValues as SelectOptions);
    return accum;
  }, [] as Array<SelectOptions>);

export const reportStatusAggregator = (status: string) => {
  const statusArray = status.split(',').length;
  if (statusArray > 2) return 'Full Report';
  if (statusArray > 1) return 'Partial Report';
  return 'Insufficient Data';
};

export const mapAssessmentToReport = (listOfTypes: [], clientName: string) => {
  const listOfAvailableReports = (assessmentTypes: []) => {
    const reportsMap = {
      'saville wave': ['jlr', 'gsk', 'ldr', 'insights self', 'scr', 'lrr'],
      'saville swift': ['jlr', 'ldr', 'insights self', 'lrr'],
      'hogan hds': ['gsk', 'insights self'],
      'hogan hpi': ['ldr', 'insights self', 'lrr'],
      'hogan mvpi': ['insights self'],
      impact: ['ldr', 'insights self', 'lrr'],
      purpose: ['ldr', 'insights self', 'lrr'],
      potential: ['insights self'],
      alp: ['ldr', 'insights self', 'lrr'],
      'laq self': ['ldr', 'insights self', 'lrr'],
      'laq 360': ['insights 360'],
      hca: ['ldr', 'lrr'],
      cbi: ['alr_leader', 'alr_client'],
      'hca short form': ['hca', 'scr', 'lrr'],
      'hca long form': ['hca', 'lrr'],
    };

    const availableReports: [] = [];

    for (const item of assessmentTypes) {
      if (reportsMap[item] !== undefined)
        availableReports.push(...(reportsMap[item] as []));
    }

    return [...new Set(availableReports)];
  };

  const filterAvailableReportsPerClient = (
    client: string,
    availableReports: [],
    listOfTypes: []
  ) => {
    const clientsMap: { [name: string]: any } = {
      'national grid': ['insights self', 'insights 360'],
      'jaguar land rover': ['jlr'],
      gsk: ['gsk'],
      resmed: ['ldr'],
      oasis: ['insights self'],
      'the aes corporation': ['ldr'],
      'report generation testing': [
        'insights self',
        'insights 360',
        'jlr',
        'gsk',
        'ldr',
        'hca',
        'scr',
        'lrr',
      ],
      'assessment team test': [
        'insights 360',
        'insights self',
        'jlr',
        'gsk',
        'ldr',
        'hca',
        'scr',
        'lrr',
      ],
      'hca training': ['hca'],
      borgwarner: ['ldr'],
      'heidrick hr': ['ldr'],
      parkland: ['ldr', 'lrr'],
      'heidrick capabilities assessment portal': [
        'ldr',
        'lrr',
        'hca',
        'insights self',
      ],
      'mayo clinic': ['ldr', 'lrr'],
      'baptist health': ['ldr', 'lrr'],
    };

    const filteredReports: string[] = availableReports.filter((report) =>
      clientsMap[client]?.includes(report)
    );

    // Adding cbi report options to any client containing a cbi assessment
    for (const item of listOfTypes) {
      if (item === 'cbi') {
        if (clientsMap[client] === undefined) {
          // if no other report than cbi reports is available to a client then return table data here
          return [
            {
              tableName: 'Assessor Led Report - Leader',
              originalName: 'alr_leader',
            },
            {
              tableName: 'Assessor Led Report - Client',
              originalName: 'alr_client',
            },
          ];
        }

        filteredReports.push('alr_leader');
        filteredReports.push('alr_client');
      }
    }

    // if there are no reports available (either cbi reports or others) to a client then return []
    if (clientsMap[client] === undefined) return [];

    const tableReportNameMap: any = {
      'insights self': 'Insights Self-Report',
      'insights 360': 'Insights 360 Report',
      jlr: 'JLR Custom Mapped Report',
      gsk: 'GSK Custom Mapped Report',
      ldr: 'Leadership Development Report',
      alr_leader: 'Assessor Led Report - Leader',
      alr_client: 'Assessor Led Report - Client',
      hca: 'HCA Report',
      scr: 'Standardized Configurable Report',
      lrr: 'Leadership Development Role Context',
    };

    return filteredReports.map((el) => ({
      tableName: tableReportNameMap[el],
      originalName: el,
    }));
  };

  // fetching available reports to a client based on assessments
  const availableReports = listOfAvailableReports(listOfTypes);

  return filterAvailableReportsPerClient(
    clientName,
    availableReports as [],
    listOfTypes
  );
};

export const filterAvailableReportsToExtractPerClient = (client: string) => {
  const clientsMap: { [name: string]: any } = {
    jlr: [
      {
        keyValue: 'jlr_csv',
        keyName: 'Jaguar Land Rover CSV Report',
      },
    ],
    gsk: [
      {
        keyValue: 'gsk_csv',
        keyName: 'GSK CSV Report',
      },
    ],
    resmed: [
      {
        keyValue: 'ldr_csv',
        keyName: 'Leadership Development CSV Report',
      },
    ],
  };

  if (clientsMap[client] === undefined) return [];

  return clientsMap[client];
};

export const getObjectValueFromString = (obj: any, path: string): string => {
  const keys = path?.split('.');

  return keys?.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj
  );
};

export const userTypeHandler = (myPersonas: myPersonas[] | undefined) => {
  const isClientId = myPersonas
    ?.map((el) => el.clientId)
    ?.some((el) => el === undefined);

  if (!isClientId) return 'Client';

  return 'Global';
};

export const getUniqueProjectsCount = (assessmentsData: IAssessmentData[]) => {
  const getProjectsList = assessmentsData?.map((obj) => {
    return obj?.projectId;
  });

  const filterProjects = [...new Set(getProjectsList)];

  return filterProjects.length;
};

export const getUniqueParticipantsCount = (
  assessmentsData: IAssessmentData[]
) => {
  const getParticipantsList = assessmentsData.map((obj) => {
    return obj?.participant?.toLowerCase();
  });

  const filterParticipants = [...new Set(getParticipantsList)];

  return filterParticipants.length;
};

export const getUniqueCompletedAssessmentsCount = (
  assessmentsData: IAssessmentData[]
) => {
  const getAssessmentsList = assessmentsData?.filter(
    (obj) => obj?.status?.toLowerCase() === 'completed'
  );

  return getAssessmentsList.length;
};

export const getProjectStatus = (projectStats: any) => {
  let status = '';
  if (projectStats['leaders'] === projectStats['Not Invited']) {
    status = 'Not Invited';
  } else if (projectStats['leaders'] === 0) {
    status = 'Not Invited';
  } else if (
    projectStats['Not Started'] === 0 &&
    projectStats['Not Invited'] === 0 &&
    projectStats['In Progress'] === 0 &&
    projectStats['Completed'] === 0
  ) {
    status = 'Not Invited';
  } else if (projectStats['leaders'] === projectStats['Not Started']) {
    status = 'Not Started';
  } else if (projectStats['leaders'] === projectStats['In Progress']) {
    status = 'In Progress';
  } else if (projectStats['leaders'] === projectStats['Completed']) {
    status = 'Complete';
  } else if (
    projectStats['Not Invited'] == 0 &&
    projectStats['In Progress'] > 0
  ) {
    status = 'In Progress';
  } else {
    status = 'In Progress';
  }
  return status;
};
